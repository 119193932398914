<template>
  <title-bar v-if="email" :title="email.subject"/>
  <b-card v-if="email">
    <iframe :srcdoc="email.message" width="100%" height="800" id="emailIframe" @load="load"></iframe>
  </b-card>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";

export default {
  components: {
    TitleBar
  },
  data() {
    return {
      email: null,
    };
  },
  created() {
    this.loadEmail();
  },
  methods: {
    loadEmail() {
      const id = this.$route.params.id;
      this.$Emails.getResource({id}).then((response) => {
        this.email = response.data;
      });
    },
    load() {
      var iFrameID = document.getElementById('emailIframe');
      if(iFrameID) {
        iFrameID.height = "";
        iFrameID.height = iFrameID.contentWindow.document.body.scrollHeight + "px";
      }   
    }
  },
};
</script>
